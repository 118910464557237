import React, { FC } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import imagePng from "images/hero-right.png";
import HeroSearchForm from "components/HeroSearchForm/HeroSearchForm";
import { globalJson } from "global/global_json";
import img1 from "images/clientSay2.png"
import img2 from "images/clientSay3.png"
import img3 from "images/clientSay6.png"

export interface SectionHero4Props {
  className?: string;
}

const SectionHero4: FC<SectionHero4Props> = ({ className = "" }) => {
  return (
    <div
      className={`nc-SectionHero relative ${className}`}
      data-nc-id="SectionHero"
    >
      <div className="flex flex-col lg:flex-row lg:items-center">
        <div className="flex-shrink-0 lg:w-1/2 flex flex-col items-start space-y-8 sm:space-y-10 pb-14 lg:pb-64 xl:pr-14 lg:mr-10 xl:mr-0">
          <h2 className="font-bold text-3xl md:text-6xl !leading-[114%] text-[#001E02]">
            Rental Point car rental
          </h2>
          <span className="text-base md:text-lg text-neutral-500 dark:text-neutral-400">
            <div className="flex items-center">
              <div className="flex -space-x-4">
                <img
                  className="w-12 h-12 rounded-full border-4 border-white"
                  src={img1}
                  alt="Profile 1"
                />
                <img
                  className="w-12 h-12 rounded-full border-4 border-white"
                  src={img2}
                  alt="Profile 2"
                />
                <img
                  className="w-12 h-12 rounded-full border-4 border-white"
                  src={img3}
                  alt="Profile 3"
                />
              </div>
              <span className="ml-3">
                <strong>1000+</strong> Bookings in Milwaukee, WI
              </span>
            </div>
          </span>
        </div>
        <div className="flex-grow">
          <img className="w-full" src={imagePng} alt="hero" />
        </div>
      </div>

      <div className="hidden lg:block z-10 mb-12 lg:mb-0 lg:-mt-60 w-full">
        <HeroSearchForm />
      </div>
    </div>
  );
};

export default SectionHero4;
