import React, { FC, ReactNode } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";

export interface SectionHeroProps {
  className?: string;
  rightImg: string;
}

const HomeAboutSection: FC<SectionHeroProps> = ({
  className = "",
  rightImg,
}) => {
  return (
    <div
      className={`nc-SectionHero relative ${className} mb-20 lg:mb-36`}
      data-nc-id="SectionHero"
    >
      <div className="flex flex-col lg:flex-row items-center relative text-center lg:text-left lg:space-x-10">
        {/* Text Section with 50% width */}
        <div className="w-full lg:w-1/2 space-y-5 lg:space-y-7">
          <h2 className="text-3xl !leading-tight font-bold md:text-5xl text-[white]">
            Welcome to Rental Point
          </h2>
          <span className="block text-base xl:text-lg text-white text-justify md:max-w-[90%]">
            Rental Point, established in 2018, envisions providing an
            affordable, community-based alternative to major car rental
            companies, often focused on business and tourist clientele. With
            multiple locations in Milwaukee, WI, and beyond, our commitment
            revolves around prioritizing safety, productivity, and enjoyment for
            both business and leisure trips. We take pride in delivering
            exceptional service and flexibility to meet the diverse needs of our
            valued customers.
          </span>
        </div>

        {/* Image Section with 50% width */}
        <div className="w-full mt-12 lg:mt-0 lg:w-1/2 flex-grow">
          <img className="w-full rounded-xl" src={rightImg} alt="" />
        </div>
      </div>
    </div>
  );
};

export default HomeAboutSection;
