import clientSay1 from "images/clientSay1.jpg";
import clientSay4 from "images/clientSay4.jpg";
import clientSay5 from "images/clientSay5.jpg";

export const globalJson = {
  prod_company_id: 21,
  dev_company_id: 1,
  // apiUtil auth header key
  authToken: "Bearer 171|oi0d3wgjpOBpuLKAqYnmU7XkzvlDTmAzmz1GzktC",
  companyId: "b8cb93b3-d95e-4fb8-ae13-146b4f64846f",
  stripePublishableKey:
    "pk_live_51PcuOnRra0lXsHPw9BuezMbtZC4UWDoKe79TEoh0o2RR47w1UKRBUEV4NTvWw6ulDk2junjATRHqTRea7wfOUSZT00h9ATeTiV",
  mapKey: "AIzaSyDKEntg7JydrxI7zr2tQL5OYcS5M-er-bc",
  residenceStateCode: "FL",

  adminEmailToken:
    "eSARL0qbFbAkFtLBzx2FajpsjqymAJPZkuo9Ojrb7WR5XLCt4VckcgVY2I5TK10szd1F8bd7MBkR4di0oui7Z1QGpzty9Mn4oTT2Fxfx4v42nydAb7y0rSkwtHE5ZWHt",
  websiteLink: "https://rentalpoint.net/",
  sender: "Rental Point",
  ccEmail: "RentalPoint.USA@gmail.com",

  // apiUtil sendNewsLetterEmail
  util_serviceId: "service_qtp0dg4",
  util_templateId: "template_zzaqcrs",
  util_userId: "5BABZb1-X9rZHDjmd",
  util_privateKey: "PLD5wzVCYqXSYCkfsMH-a",

  clientSays_arr: [
    {
      id: 1,
      clientName: "Veronica",
      clientAddress: "Dodge Grand Caravan 2019",
      content:
        "Delivery & pick up was prompt and friendly. Communication was consistent and clear. The car was very clean and reliable. Will definitely be using Rental Point again.",
      img: clientSay1,
    },
    {
      id: 2,
      clientName: "Ethan",
      clientAddress: "LondDodge Grand Caravan 2019",
      content:
        "JRental Point and Arkady is an absolutely EXCELLENT host. Car was clean, ran well and customer service was top notch. A very honest, reliable and communicative host",
      img: clientSay4,
    },
    {
      id: 3,
      clientName: "Frederic",
      clientAddress: "Dodge Grand Caravan 2019",
      content:
        "Great experience! Comfy car, good mileage and decent power- perfect for quick wedding trip out for the weekend in Sheboygan.",
      img: clientSay5,
    },
    {
      id: 4,
      clientName: "Tiffany",
      clientAddress: "Dodge Grand Caravan 2019",
      content:
        "I just love these guys their clean respectful and on time they really making it hard for me to let go n get my own car 😆because I just love renting from them 🫶🏼",
      img: clientSay5,
    },
    {
      id: 5,
      clientName: "Andrea",
      clientAddress: "Dodge Grand Caravan 2019",
      content:
        "This was an easy and positive way to rent a vehicle for our trip. We had never used Turo prior to this trip. Rental Point made things easy and communicated clearly with us every step of the way.",
      img: clientSay5,
    },
  ],

  //social links
  facebook: "https://www.facebook.com/people/Rental-Point/100083241186695/", //pending
  instagram: "https://www.instagram.com/", //pending

  //contact
  address: "10401 N Port Washington Rd Mequon, WI  53092 United States",
  addressLink:
    "https://www.google.com/maps/place/10401+N+Port+Washington+Rd,+Mequon,+WI+53092,+USA/data=!4m2!3m1!1s0x8804e165be3310f9:0x472f74060b555afa?sa=X&ved=1t:242&ictx=111",
  phone: "(414) 364-1121",
  email: "RentalPoint.USA@gmail.com",
};

export const scriptHubSpot = document.createElement("script");
scriptHubSpot.src = "//js-eu1.hs-scripts.com/145568662.js";
scriptHubSpot.async = true;
scriptHubSpot.defer = true;
scriptHubSpot.id = "hs-script-loader";
