import rightImg from "images/about-img.png";
import rightImg1 from "images/abouting2.png";
import React, { FC } from "react";
import SectionFounder from "./SectionFounder";
import SectionStatistic from "./SectionStatistic";
import { Helmet } from "react-helmet";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import SectionHero from "./SectionHero";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import { globalJson } from "global/global_json";
import GoPlacesWith from "components/GoPlacesWith/GoPlacesWith";
import Features from "containers/Feature/Feature";
import SectionHero2 from "./SectionHero2";
import GoPlacesWith2 from "components/GoPlacesWith2/GoPlacesWith2";

export interface PageAboutProps {
  className?: string;
}

const PageAbout: FC<PageAboutProps> = ({ className = "" }) => {
  return (
    <div
      className={`nc-PageAbout overflow-hidden relative ${className}`}
      data-nc-id="PageAbout"
    >
      <Helmet>
        <title>About</title>
      </Helmet>

      {/* ======== BG GLASS ======== */}
      <BgGlassmorphism />

      <div className="container py-12 ">
        <SectionHero
          rightImg={rightImg}
          heading="Welcome to Rental Point"
          btnText=""
          subHeading="Rental Point, established in 2018, envisions providing an affordable, community-based alternative to major car rental companies, often focused on business and tourist clientele. With multiple locations in Milwaukee, WI, and beyond, our commitment revolves around prioritizing safety, productivity, and enjoyment for both business and leisure trips. We take pride in delivering exceptional service and flexibility to meet the diverse needs of our valued customers."
        />
      </div>
      <div className="container py-12 ">
        <SectionHero2
          rightImg={rightImg1}
          heading="Our Vision"
          btnText=""
          subHeading="Our vision fulfills the need for an affordable community-based alternative to the major car rental companies who primarily cater to the business and tourist class. Rental Point was founded in 2018 and now has multiple locations in Milwaukee WI and out-of-state. At Rental Point, we prioritize the safety, productivity, and enjoyment of your trips, whether they are for business or leisure purposes. Our commitment is to provide exceptional service and flexibility to assist our valued customers. Our fleet of vehicles is designed to cater to various preferences and requirements."
        />
      </div>

      <div className="relative p-16">
        <GoPlacesWith2 />
      </div>
      <div className="py-16 ">
        <Features />
      </div>

      {/* <BackgroundSection /> */}
      <div className="bg-[#FEDBDC] mt-12">
        <div className="relative py-16 mb-20 lg:mb-36">
          <SectionClientSay uniqueClassName="PageAbout_" />
        </div>
      </div>

      {/* <SectionStatistic />

      <SectionSubscribe2 /> */}
    </div>
  );
};

export default PageAbout;
